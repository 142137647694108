import React, { useEffect, useState } from 'react';
import './App_admin.scss';
import axios from 'axios';

export function AdvantageMap({ item, keyAxios, dataEdit, setEdit }) {
  const [columnsSet, setColumnsSet] = useState()

  const [editColumn, setEdutColumns] = useState(false)
  useEffect(() => {
    if (item.advantage) {
      const json = JSON.parse(item.advantage)
      setColumnsSet(json)
    }
  }, [])

  if (editColumn) {
    const value = JSON.stringify(columnsSet);
    axios({
      method: 'post',
      url: 'https://api.bezlimit.club/text_field',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
        'Accept': 'application/json',
      },
      data: {
        key: keyAxios,
        val: value,
      }
    }).then((res) => {
      if (res.data.Response.message === "OK") {
        setEdit(!dataEdit);
        setEdutColumns(false)
      }
    })
  }

  return (
    <>
      {columnsSet?.map((elem, index) => {
        return (
          <AdvantageP key={index} elem={elem} columnsSet={columnsSet} setColumnsSet={setColumnsSet} setEdutColumns={setEdutColumns}></AdvantageP>
        )
      })
      }
    </>
  )
}

function AdvantageP({ elem, columnsSet, setColumnsSet, setEdutColumns }) {
  const [H, setH] = useState(elem.h);
  const [errorH, setErrorH] = useState(false);
  const [editValueH, setEditValueH] = useState(false);
  const [P, setP] = useState(elem.p);
  const [errorP, setErrorP] = useState(false);
  const [editValueP, setEditValueP] = useState(false);

  function canselButton() {
    setH(elem.h);
    setEditValueH(false);
    setP(elem.p);
    setEditValueP(false);
  }

  useEffect(() => {
    (H !== elem.h) ? setEditValueH(true) : setEditValueP(false);
    const editTextName = document.getElementById(elem.h);
    editValueH ? editTextName.className = 'edit' : editTextName.className = '';

    if (H === '') {
      editTextName.className = 'error';
      setErrorH(true);
    } else {
      setErrorH(false)
    }

    (P !== elem.p) ? setEditValueP(true) : setEditValueP(false);
    const editText = document.getElementById(elem.p);
    editValueP ? editText.className = 'edit' : editText.className = '';

    if (P === '') {
      editText.className = 'error';
      setErrorP(true);
    } else {
      setErrorP(false)
    }

  }, [H, setH, P, setP, elem])

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  return (
    <>
      <div key={elem.id}>
        <h4>Преимущество #{elem.id}</h4>
        <form className='grid'>
          <p>Заголовок {elem.id}</p>
          <div>
            <textarea id={elem.h} rows={3} value={H} placeholder='Введите текст' onChange={(e) => setH(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}></textarea>
            {errorH ? <p className='error_p'>Обязательное поле для заполнения</p> : <></>}
          </div>
          <p>Описание {elem.id}</p>
          <div>
            <textarea id={elem.p} rows={3} value={P} placeholder='Введите текст' onChange={(e) => setP(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}></textarea>
            {errorP ? <p className='error_p'>Обязательное поле для заполнения</p> : <></>}
          </div>
        </form>
        {(editValueH || editValueP) ? <div className='div_button'>
          <button onClick={() => canselButton()}>Отменить изменения</button>
          <button className='button_sub' onClick={() => {
            if (!errorH && !errorP) {
              setP(P.trim())
              setH(H.trim())
              const newColumns = columnsSet.slice();
              newColumns.splice(elem.id - 1, 1, { id: elem.id, h: H, p: P, })
              setColumnsSet(newColumns);
              setEdutColumns(true)
            }
          }}>Сохранить</button>
        </div> : <></>}
      </div>

    </>
  )
}

export default AdvantageMap;
import React, { useEffect, useState } from 'react';
import './App_admin.scss';
import axios from 'axios';

export function AboutColumnsMap({ item, keyAxios, dataEdit, setEdit }) {
  const [columnsSet, setColumnsSet] = useState()

  const [editColumn, setEdutColumns] = useState(false)
  useEffect(() => {
    if (item.aboutColumnsP) {
      const json = JSON.parse(item.aboutColumnsP)
      setColumnsSet(json)
    }
  }, [])

  if (editColumn) {
    const value = JSON.stringify(columnsSet);
    axios({
      method: 'post',
      url: 'https://api.bezlimit.club/text_field',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
        'Accept': 'application/json',
      },
      data: {
        key: keyAxios,
        val: value,
      }
    }).then((res) => {
      if (res.data.Response.message === "OK") {
        setEdit(!dataEdit);
        setEdutColumns(false)
      }
    })
  }

  return (
    <>
      {columnsSet?.map((elem, index) => {
        return (
          <AboutColumnsP key={index} elem={elem} columnsSet={columnsSet} setColumnsSet={setColumnsSet} setEdutColumns={setEdutColumns}></AboutColumnsP>
        )
      })
      }
    </>
  )
}

function AboutColumnsP({ elem, columnsSet, setColumnsSet, setEdutColumns }) {
  const [bigP, setBigP] = useState(elem.big_p);
  const [errorBigP, setErrorBigP] = useState(false);
  const [editValueBigP, setEditValueBigP] = useState(false);
  const [littleP, setLittleP] = useState(elem.little_p);
  const [errorLittleP, setErrorLittleP] = useState(false);
  const [editValueLittleP, setEditValueLitteP] = useState(false);

  function canselButton() {
    setBigP(elem.big_p);
    setEditValueBigP(false);
    setLittleP(elem.little_p);
    setEditValueLitteP(false);
  }

  useEffect(() => {
    (bigP !== elem.big_p) ? setEditValueBigP(true) : setEditValueBigP(false);
    const editTextName = document.getElementById(elem.big_p);
    editValueBigP ? editTextName.className = 'edit' : editTextName.className = '';

    if (bigP === '') {
      editTextName.className = 'error';
      setErrorBigP(true);
    } else {
      setErrorBigP(false)
    }

    (littleP !== elem.little_p) ? setEditValueLitteP(true) : setEditValueLitteP(false);
    const editText = document.getElementById(elem.little_p);
    editValueLittleP ? editText.className = 'edit' : editText.className = '';

    if (littleP === '') {
      editText.className = 'error';
      setErrorLittleP(true);
    } else {
      setErrorLittleP(false)
    }

  }, [bigP, setBigP, littleP, setLittleP, elem])

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  return (
    <>
      <div key={elem.id}>
        <h4>Колонка #{elem.id}</h4>
        <form className='grid'>
          <p>Большая надпись {elem.id}</p>
          <div>
            <textarea id={elem.big_p} rows={3} value={bigP} placeholder='Введите текст' onChange={(e) => setBigP(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}></textarea>
            {errorBigP ? <p className='error_p'>Обязательное поле для заполнения</p> : <></>}
          </div>
          <p>Маленькая надпись {elem.id}</p>
          <div>
            <textarea id={elem.little_p} rows={3} value={littleP} placeholder='Введите текст' onChange={(e) => setLittleP(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}></textarea>
            {errorLittleP ? <p className='error_p'>Обязательное поле для заполнения</p> : <></>}
          </div>
        </form>
        {(editValueBigP || editValueLittleP) ? <div className='div_button'>
          <button onClick={() => canselButton()}>Отменить изменения</button>
          <button className='button_sub' onClick={() => {
            if (!errorBigP && !errorLittleP) {
              setLittleP(littleP.trim())
              setBigP(bigP.trim())
              const newColumns = columnsSet.slice();
              newColumns.splice(elem.id - 1, 1, { id: elem.id, big_p: bigP, little_p: littleP, })
              setColumnsSet(newColumns);
              setEdutColumns(true)
            }
          }}>Сохранить</button>
        </div> : <></>}
      </div>

    </>
  )
}

export default AboutColumnsMap;
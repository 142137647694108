import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './App_admin.scss';

function Authorization() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  function SubmitAuthor() {
    if (username !== '' && password !== '') {
      const axiosData = async () => {
        await axios({
          method: 'post',
          url: 'https://api.bezlimit.club/token',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: {
            username: username,
            password: password,
          }
        }).then((res) => {
          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("token_type", res.data.token_type);
          navigate("/admin_home");
        }).catch(function (error) {
          window.location.reload();
        })
      }
      axiosData()
    }
  }

  return (
    <div className='App_admin'>
      <form className='form_author'>
        <div className='header-content-logo-bezlimit'>
          <div className='header-icon-logo'></div>
          <p className='header-logo-bezlimit'>Безлимит Клуб</p>
        </div>
        <input type='text' value={username} onChange={(e) => setUsername(e.target.value)}></input>
        <input type='password' value={password} onChange={(e) => setPassword(e.target.value)}></input>
        <button type='button' className='button_sub' onClick={() => SubmitAuthor()}>Войти</button>
      </form>
    </div>
  )

}

export default Authorization;
import './App.css';
import { HiOutlineChevronDown, HiOutlineChevronUp, HiOutlineX, HiOutlineCheckCircle } from 'react-icons/hi';
import { ChoiceOfDelivery, ChoiceOfPayment } from './data';
import { useState } from 'react';
import InputMask from "react-input-mask";
import { Link } from 'react-router-dom';
import axios from "axios";

export function DeliveryForm({ elem, setCheckNumber }) {
  const [activeRadioDelivery, setActiveRadioDelivery] = useState(ChoiceOfDelivery[0].id);
  const [activeRadioPayment, setActiveRadioPayment] = useState(ChoiceOfPayment[0].id);
  const [successfulOrder, setSuccessfulOrder] = useState('');

  const [name, setName] = useState('');
  const [numberPhone, setNumberPhone] = useState('');
  const [address, setAddress] = useState('');
  const [comment, setComment] = useState('');
  const [email, setEmail] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [checkDescription, setCheckDescription] = useState(false); // описание способа получения номера

  const [errorTel, setErrorTel] = useState(false)
  function handleBlur() {
    if (numberPhone.indexOf('_') !== -1) {
      setErrorTel(true);
    } else {
      setErrorTel(false);
    }
  }

  function submitForm(item) {
    setSuccessfulOrder(item);
    const choiceOfDelivery = ChoiceOfDelivery[activeRadioDelivery].name;
    const choiceOfPayment = ChoiceOfPayment[activeRadioPayment].name;
    switch (activeRadioDelivery) {
      case 0:
        axios(`https://www.anncom.ru/dialer/lendingbezlimit/registration_number_club.py`, {
          params: {
            // user_id: localStorage.getItem('bez_session'),
            phone_take: elem.phone,
            phone_tariff: elem.tariff_price,
            phone_id: elem.id,
            cli_name: name,
            cli_phone: numberPhone,
            cli_city: address,
            cli_comment: comment,
            cli_how_to_take: choiceOfDelivery,
            cli_how_pay: choiceOfPayment,
          }
        })
        break
      case 1:
        axios(`https://www.anncom.ru/dialer/lendingbezlimit/registration_number_club.py`, {
          params: {
            user_id: localStorage.getItem('bez_session'),
            phone_take: elem.phone,
            phone_tariff: elem.tariff_price,
            phone_id: elem.id,
            cli_name: name,
            cli_phone: numberPhone,
            cli_city: address,
            cli_comment: comment,
            cli_how_to_take: choiceOfDelivery,
            cli_how_pay: choiceOfPayment,
          }
        })
        break
      case 2:
        axios(`https://www.anncom.ru/dialer/lendingbezlimit/registration_number_club.py`, {
          params: {
            user_id: localStorage.getItem('bez_session'),
            phone_take: elem.phone,
            phone_tariff: elem.tariff_price,
            phone_id: elem.id,
            cli_name: name,
            cli_phone: numberPhone,
            cli_comment: comment,
            cli_email: email,
            cli_how_to_take: choiceOfDelivery,
            cli_how_pay: choiceOfPayment,
          }
        })
        break
      case 3:
        axios(`https://www.anncom.ru/dialer/lendingbezlimit/registration_number_club.py`, {
          params: {
            user_id: localStorage.getItem('bez_session'),
            phone_take: elem.phone,
            phone_tariff: elem.tariff_price,
            phone_id: elem.id,
            cli_name: name,
            cli_phone: numberPhone,
            cli_ser_num: serialNumber,
            cli_how_to_take: choiceOfDelivery,
            cli_how_pay: choiceOfPayment,
          }
        })
        break
      default:
        break
    }

  }

  function handleName(e) {
    let value = e.target.value.replace(/[0-9]/g, '');
    setName(value);
  }

  function handelEmail(e) {
    setEmail(e.target.value);
  }

  function handleSerialNumber(e) {
    let value = e.target.value.replace(/\D/g, '');
    setSerialNumber(value);
  }

  function closeSuccessfulOrder() {
    setCheckNumber('');
    window.scrollTo(0, 0);
  }

  return (
    <div>
      {successfulOrder ? (<div>
        <Link className='link' to='/'>
          <div className='hamburger-back' onClick={() => closeSuccessfulOrder()}></div>
        </Link>
        <div className='notification-wrapper'>
          <Link className='link' to='/'>
            <HiOutlineX className='notification-icon' onClick={() => closeSuccessfulOrder()} />
          </Link>
          <div className='notification-grid'>
            <HiOutlineCheckCircle className='order-icon' />
            <div className='margin-bottom'></div>
            <div className='margin-bottom'></div>
            <p className='notificarion-h'>Заявка успешно оформлена</p>
            <div>
              <p className='order-p'>Заявка на доставку SIM с номером принята</p>
              <p className='order-p'>В ближайшее время с Вами свяжется наш менеджер</p>
            </div>
            <div className='notification-description-grid2'>
              <p className='notification-description-h p10 flex'>Сумма к оплате</p>
              <p className='notification-description-h p10 flex'>Тариф</p>
              <p className='order-grid-p flex'>{elem.tariff_price}</p>
              <p className='order-grid-p flex'>На связи {elem.tariff_price}</p>
            </div>
            <div>
              {activeRadioDelivery === 1 ? <p className='order-p'>Бесплатная доставка SIM-карты с номером</p> : null}
              {activeRadioPayment === 1 ? <p className='order-p'>Оплата после получения SIM</p> : null}
            </div>
            <div className='margin-bottom'></div>
          </div>
        </div>
      </div>) : null}
      <div>
        <a className='fixed-whatsapp' href='https://wa.me/79389887777?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D0%BF%D0%BE%D0%B4%D1%81%D0%BA%D0%B0%D0%B6%D0%B8%D1%82%D0%B5'>
          <svg className='fixed-whatsapp-icon'></svg>
        </a>
        <div className='header-background background-number' />
        <div className='delivery-wrapper'>
          <p className='header-content-font h5'>Выберите подходящий способ получения номера</p>
          <p className='header-content-bezlimit margin-bottom'>{elem.numberPhone}</p>
          <form>
            <div className='delivery-radio-div' >
              {ChoiceOfDelivery.map(elem => {
                return (
                  <div key={elem.id} onClick={() => setActiveRadioDelivery(elem.id)}>
                    <div>
                      <input type='radio' id={elem.name} checked={elem.id === activeRadioDelivery} onChange={() => setActiveRadioDelivery(elem.id)} />
                      <label htmlFor={elem.name} className='header-content-font h7'> {elem.name}</label>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='delivery-description-wrapper'>
              <div className='catalog-card-header' onClick={() => setCheckDescription(!checkDescription)}>
                <p className='catalog-card-description-p'>{ChoiceOfDelivery[activeRadioDelivery].name}</p>
                {checkDescription ? <HiOutlineChevronUp className='card-icon-chevron' /> : <HiOutlineChevronDown className='card-icon-chevron' />}
              </div>
              {checkDescription ? (activeRadioDelivery === 0) ? (<div>
                <p className='h7'>Для создания заказа заполните все необходимые поля</p>
                <p className='h7'>После оформления заказа на получение номера с Вами свяжется менеджер отдела логистики для подтверждения и согласования ближайшего салона Билайн</p>
              </div>) : ((activeRadioDelivery === 1) ? (<div>
                <p className='h7'>Для создания заказа заполните все необходимые поля</p>
                <p className='h7'>После оформления заказа на получение номера с Вами свяжется менеджер отдела логистики для подтверждения и согласования времени и адреса доставки</p>
              </div>) : ((activeRadioDelivery === 2) ? (<div>
                <p className='h7'>Виртуальная карта eSIM оформляется быстро и удаленно. Главное — убедиться, что Ваш телефон поддерживает эту функцию.</p>
                <p className='h7'>Для оформления заполните все необходимые поля</p>
                <p className='h7'>На указанную почту придет инструкция для самостоятельной установки eSIM</p>
              </div>) : (<div>
                <p className='h7'>Подходит для того случая, когда у Вас уже есть пустая SIM-карта</p>
                <p className='h7'>При выборе этого способа необходимо ввести ее серийный номер и пополнить баланс на сумму, равную абонентской плате</p>
              </div>))) : null}

            </div>
            <input className='back-form-input' type='name' autoComplete='name' placeholder='ФИО получателя' name="name" value={name} onChange={(e) => handleName(e)} ></input>
            <div className='div-error-tel'>
              <InputMask className='back-form-input' type='phone' autoComplete='tel-area-code' name='phone' mask="+7(999) 999-99-99" placeholder="+7(000) 000-00-00" value={numberPhone} onChange={(e) => setNumberPhone(e.target.value)} onBlur={() => handleBlur()} />
              {errorTel ? <p className='back-form-p error-tel'>Введите номер телефона</p> : <></>}
            </div>
            {(activeRadioDelivery === 0) ? (<div>
              <input className='back-form-input' type='address' autoComplete='off' placeholder='Город' name="address" value={address} onChange={(e) => setAddress(e.target.value)} ></input>
            </div>) : ((activeRadioDelivery === 1) ? (<div>
              <input className='back-form-input' type='address' autoComplete='off' placeholder='Адрес (Город, улица, дом, квартира)' name="address" value={address} onChange={(e) => setAddress(e.target.value)} ></input>
            </div>
            ) : ((activeRadioDelivery === 2) ? (<div>
              <input className='back-form-input' type='email' autoComplete='email' placeholder='Электронная почта' name="email" value={email} onChange={(e) => handelEmail(e)} ></input>
            </div>) : (<div>
              <input className='back-form-input' type='serialNumber' autoComplete='off' placeholder='Серийный номер' name="serialNumber" value={serialNumber} onChange={(e) => handleSerialNumber(e)} ></input>
            </div>)))}
            {(activeRadioDelivery !== 3) ? (<textarea className='back-form-input' type='comment' placeholder='Комментарий' name="comment" value={comment} onChange={(e) => setComment(e.target.value)} ></textarea>) : null}
            {(activeRadioDelivery === 0 || activeRadioDelivery === 1) ? (
              <div className='delivery-radio-div'>
                {ChoiceOfPayment.map(elem => {
                  return (
                    <div key={elem.id} onClick={() => setActiveRadioPayment(elem.id)}>
                      <input type='radio' id={elem.name} checked={elem.id === activeRadioPayment} onChange={() => setActiveRadioPayment(elem.id)} />
                      <label htmlFor={elem.name} className='header-content-font h7'> {elem.name}</label>
                    </div>
                  )
                })}
              </div>) : null}
            <div className='div-center'>
              <button className='button-mobile' type="button" onClick={() => closeSuccessfulOrder('')}>Вернуться назад</button>
              {(activeRadioDelivery === 0 || activeRadioDelivery === 1) ? (
                <button className='button-back catalog-card-button' disabled={!name || !numberPhone || !address || numberPhone.indexOf('_') !== -1} type="button" onClick={() => submitForm(elem)}>Оформить заявку</button>
              ) : ((activeRadioDelivery === 2) ? (
                <button className='button-back catalog-card-button' disabled={!name || !numberPhone || email.indexOf('@') === -1 || numberPhone.indexOf('_') !== -1} type="button" onClick={() => submitForm(elem)}>Оформить заявку</button>
              ) : (
                <button className='button-back catalog-card-button' disabled={!name || !numberPhone || !serialNumber || numberPhone.indexOf('_') !== -1} type="button" onClick={() => submitForm(elem)}>Оформить заявку</button>
              ))}
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}
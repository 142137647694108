import React, { useEffect, useState } from 'react';
import './App_admin.scss';
import { Link as ScrollLink } from 'react-scroll';
import { HiArrowUp } from "react-icons/hi";
import RewiewMap from './rewiew';
import ReactLoading from 'react-loading';
import axios from 'axios';
import AboutColumnsMap from './columns';
import { InputText, InputImage, InputVideo } from './inputs';
import AdvantageMap from './advantage';
import { Link } from 'react-router-dom';

function AppAdmin() {
  const [dataEdit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false)
  const [dataValue, setDataValue] = useState('')

  const [imgList, setImgList] = useState([]);

  useEffect(() => {
    setLoading(true)
    axios({
      method: 'get',
      url: 'https://api.bezlimit.club/all_data'
    }).then(res => {
      setImgList([])
      localStorage.setItem("data", JSON.stringify(res.data.Response))
      const keysImgList = Object.keys(res.data.Response).filter(str => str.startsWith('image'))
      keysImgList.map(key => {
        setImgList(imgList => [
          ...imgList,
          {
            name: key,
            image: res.data.Response[key]
          },
        ]);
      })
      setLoading(false)
    })
      .then(() => {
        setDataValue(JSON.parse(localStorage.getItem("data")))
      })
  }, [dataEdit])

  return (
    <div className='App_admin'>
      {(loading) ? <div className="wrap_number">
        <ReactLoading type='spinningBubbles' color='black' height={'50px'} width={'50px'} />
      </div> :
        <div>
          <SkrollUp></SkrollUp>
          <div className='header'>
            <div className='header-menu'>
              <div className='header-content-logo-bezlimit'>
                <div className='header-icon-logo'></div>
                <p className='header-logo-bezlimit'>Безлимит Клуб</p>
              </div>
              <ScrollLink className='header-menu-h' to='baseNumbers' smooth={true}>База номеров</ScrollLink>
              <ScrollLink className='header-menu-h' to='rewiewSkroll' smooth={true}>Отзывы</ScrollLink>
              <ScrollLink className='header-menu-h' to='about' smooth={true}>О нас</ScrollLink>
              <ScrollLink className='header-menu-h' to='advantage' smooth={true}>Почему выбирают нас?</ScrollLink>
              <ScrollLink className='header-menu-h' to='part' smooth={true}>Стать партнером</ScrollLink>
              <Link className='header-menu-h' to='/admin'>Выйти</Link>
            </div>
          </div>
          <div className="App">
          <div>
              <h3>Ссылки</h3>
              <InputText item={dataValue.numberPhone} keyAxios={'numberPhone'} title='Номер телефона' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.numberPhone2} keyAxios={'numberPhone2'} title='Номер телефона дополнительный' obligatorily={true} dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.WhatsApp} keyAxios={'WhatsApp'} title='WhatsApp' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.Telegram} keyAxios={'Telegram'} title='Telegram' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.VK} keyAxios={'VK'} title='VK' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.know} keyAxios={'know'} title='Кнопка "Узнать"' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.manager} keyAxios={'manager'} title='Кнопка "Написать менеджеру"' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.fixedWhats} keyAxios={'fixedWhats'} title='Фиксированный WhatsApp' dataEdit={dataEdit} setEdit={setEdit}></InputText>
            </div>
            <div name='baseNumbers'>
              <h3>База номеров</h3>
              <InputText item={dataValue.baseNumbersP1} keyAxios={'baseNumbersP1'} title='Описание 1' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.baseNumbersP2} keyAxios={'baseNumbersP2'} title='Описание 2' dataEdit={dataEdit} setEdit={setEdit}></InputText>
            </div>
            <div name='rewiewSkroll'></div>
            <div>
              <h3>Отзывы</h3>
              <RewiewMap dataValue={dataValue.comment} dataEdit={dataEdit} setEdit={setEdit}></RewiewMap>
              <h3>Бесплатный тариф</h3>
              <InputText item={dataValue.tariffFreeH} keyAxios={'tariffFreeH'} title='Заголовок' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.tariffFreeP} keyAxios={'tariffFreeP'} title='Описание' dataEdit={dataEdit} setEdit={setEdit}></InputText>
            </div>
            <div name='about'>
              <h3>О нас</h3>
              <InputVideo item={dataValue.aboutVideo} title='Видео' dataEdit={dataEdit} setEdit={setEdit}></InputVideo>
              <InputText item={dataValue.aboutVideoP} keyAxios={'aboutVideoP'} title='Описание под видео' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <AboutColumnsMap item={dataValue} keyAxios={'aboutColumnsP'} dataEdit={dataEdit} setEdit={setEdit}></AboutColumnsMap>
              <h4>Фотографии</h4>
              <button type='button' onClick={() => setImgList([...imgList, { key: '', file: '' }])}>Добавить фото</button>
              {
                imgList.map((elem, index) => {
                  return (
                    <InputImage key={index} item={elem} title={'Фото #' + (index + 1)} index={index} dataEdit={dataEdit} setEdit={setEdit}></InputImage>
                  )
                })
              }

            </div>
            <div name='advantage'>
              <h3>Почему выбирают нас?</h3>
              <AdvantageMap item={dataValue} keyAxios={'advantage'} dataEdit={dataEdit} setEdit={setEdit}></AdvantageMap>
            </div>
            <div name='part'>
              <h3>Стать партнером</h3>
              <InputText item={dataValue.becomePartnerH} keyAxios={'becomePartnerH'} title='Заголовок' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.becomePartnerP} keyAxios={'becomePartnerP'} title='Описание' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <h4>Подвал</h4>
              <InputText item={dataValue.footerPDesktop} keyAxios={'footerPDesktop'} title='Описание в подвале версия ПК' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.footerPMobile} keyAxios={'footerPMobile'} title='Описание в подвале мобильная версия' dataEdit={dataEdit} setEdit={setEdit}></InputText>
              <InputText item={dataValue.additionalField} keyAxios={'additionalField'} title='Дополнительное поле' obligatorily={true} dataEdit={dataEdit} setEdit={setEdit}></InputText>
            </div>
          </div>
        </div>}
    </div>
  );
}



function SkrollUp() {
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {scroll > 100 ? <span>
        <HiArrowUp onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })} className="scroll-top"></HiArrowUp>
      </span> : <></>}
    </>
  )
}




export default AppAdmin;
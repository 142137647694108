export const Rewiews = [
    {
        id: 1,
        ava: 'Rectangle 729.png',
        name: 'Арслан Насыров',
        star: 5,
        rewiew: 'Всем салам! У меня был момент сомнения. Опишу как у меня происходил процесс покупки номера,может кому пригодится. Выбрал номер, мне его забронировали, скинули адреса салонов Билайна, я пошел в ближайший, мне выдали симку, (кстати в билайне только симку выдают, включение и регистрация номера менеджер делает) скинул фото штрих-кода, без всяких оплат мне менеджер Кирилл включил номер на доверии всё, оформил на паспорт, потом я оплатил номер и пополнил на месяц себе связь. Более того он мне снизил тариф потом. Короче спасибо Кирюха 🤝',
    },
    {
        id: 2,
        ava: 'Rectangle 729-2.png',
        name: 'Артур Робертович',
        star: 5,
        rewiew: 'Сделал себе за пару месяцев абонентскую плату 0р в месяц на постоянке) имея золотой номер)) я хз где такое еще можно сделать. А так отличный оператор, за достаточно длинное время использования этим оператором не случилось ни одной глобалоной проблемы. Всегда все стабильно, всегда на связи. Спасибо Безлимит Клуб!!!',
    },
    {
        id: 3,
        ava: 'Rectangle 729-3.png',
        name: 'Андрей Д.',
        star: 5,
        rewiew: 'На этом сайте большой выбор красивых номеров. Самостоятельно можно подобрать и оформить номер не посещая офис. Это крутая и удобная фишка. Тарифы выгоднее чем у других операторов. Деньги снимают посуточно, тоже удобно, не нужно сразу платить за весь месяц (кроме первого месяца). Легко и просто можно стать дилером и подключать родственников, друзей, знакомых получая свой процент. С Безлимит Клубом я примерно три года, имею несколько красивых номеров. Радует, что безлимит клуб развивается, делает розыгрыши призов среди своих дилеров. Всем рекомендую этот сайт.',
    },
    {
        id: 4,
        ava: 'Rectangle 729-4.png',
        name: 'Наталья Жукова',
        star: 5,
        rewiew: 'Купила тут сим-карту безлимит, выбрала с красивым номером. Стоимость карты была в разумных пределах, этим и подкупили. Работает нормально, никаких сбоев, все четко. Довольна.',
    },
    {
        id: 5,
        ava: 'Rectangle 729-5.png',
        name: 'Виктор И.',
        star: 5,
        rewiew: 'Очень нравится оператор. Пользуюсь больше двух лет, связью очень доволен, интернет летает, красивые номера. Цены приемлемые, доставка мгновенная. Поддержка так же удобная, помогают максимально быстро. В общем, компания достойная, связь отличная.',
    },
    {
        id: 6,
        ava: 'Rectangle 729-6.png',
        name: 'Руслан Еремин',
        star: 5,
        rewiew: 'Примерно год назад сделал для себя открытие что помимо операторов которых пиарят каждый день по телеку есть и гораздо лучше . Говорю именно про Безлимит Клуб. Качественная , стабильная связь и интернет это главное , а также бонусом это Тех. Поддержка которая не заставляет ждать до китайской пасхи. Отвечают и помогают решать проблемы быстрее, чем на операторах МТС и Мегафон и еще адекватные цены выгоднее чем у конкурентов. Видно компания заботится и дорожит своими клиентами.',
    },
    {
        id: 7,
        ava: 'Rectangle 729-7.png',
        name: 'Движ Париж',
        star: 5,
        rewiew: 'Купили себе на этом сайте симкарту с тарифом, в ближайшем салоне Билайна забрали эту симкарту, оформили быстро, за это отдельное спасибо. Красивый номер дёшево, связь ловет везде. Так после пересадил всех родственников на него. 10/10/10',
    },
    {
        id: 8,
        ava: 'Rectangle 729-8.png',
        name: 'Альберт Ишмуратов',
        star: 5,
        rewiew: 'Отличный оператор! Ловит там, где другие теряют сигнал. Красивый номер за ооочень демократичную цену. Рекомендую bezlimit.club',
    },
    {
        id: 9,
        ava: 'Rectangle 729-9.png',
        name: 'Весёлый',
        star: 5,
        rewiew: 'Доброго времени суток, леди и джентльмены! Пишу спасибо Безлимит  Клубу! Всё время, до него пользовался мегафон, но вот уже больше 2х лет пользуюсь этой связью, которую посоветовал и помог подключить близкий друг и я не пожалел! Плачу по сути копейки в отличии от "трех китов" сотовых операторов! Связь замечательная, берет и работает везде где я только не был, на просторах нашей необъятной родины, интернет отличный, пакет смс истратить практически не реально! Вообщем подключайтесь и будет вам добро!!! Спасибо «Безлимит Клуб»)',
    }
]

export const Advantage = [
    {
        id: 1,
        icon: 'mastercard.svg',
        h: 'Самая большая база в РФ',
        p: 'Имеем самую большую базу красивых номеров в России',
    },
    {
        id: 2,
        icon: 'rocket.svg',
        h: 'Бесплатная доставка',
        p: 'Бесплатная доставка SIM карт по всей России',
    },
    {
        id: 3,
        icon: 'user arrows.svg',
        h: 'Более 500 тыс. абонентов',
        p: 'Более 500 тысяч довольных пользователей советуют нас своим друзьям и знакомым',
    },
    {
        id: 4,
        icon: 'favorite.svg',
        h: 'Абонентская плата 0 руб/мес',
        p: 'Мы предосталяем уникальную возможность абоненту не платить за связь. Такого нет ни у одного оператора!',
    },
    {
        id: 5,
        icon: 'comment dots.svg',
        h: 'Круглосуточная поддержка',
        p: 'Сервис и поддержка работают 24/7',
    },
    {
        id: 6,
        icon: 'toggle on.svg',
        h: 'Моментальное подключение',
        p: 'Возможность подключать номера в любое время суток',
    },
]

export const AboutImage = [
    {
        id: 1,
        image: 'Rectangle.png'
    },
    {
        id: 2,
        image: 'Rectangle-2.png'
    },
    {
        id: 3,
        image: 'Rectangle-3.png'
    },
    {
        id: 4,
        image: 'Rectangle-4.png'
    },
    {
        id: 5,
        image: 'Rectangle-5.png'
    },
    {
        id: 6,
        image: 'Rectangle-6.png'
    },
]

export const DetailsNotif = [
    {
        id: 1,
        name: 'Билайн',
        img: ['Beeline 2021 Icon.svg']
    },
    {
        id: 2,
        name: 'Соц сети',
        img: ['VK Compact.svg', 'OK.ru.svg']
    },
    {
        id: 3,
        name: 'Мессенджеры',
        img: ['Whatsapp.svg', 'Telegram.svg', 'viber-icon-logo-svgrepo-com.svg', 'skype-svgrepo-com.svg']
    },
    {
        id: 4,
        name: 'Видео',
        img: ['youtube-svgrepo-com-2.svg', 'brand-tiktok-sq-svgrepo-com.svg']
    },
    {
        id: 5,
        name: 'Музыку',
        img: ['VK Compact.svg', 'yandex_cyr_icon-icons.com_61627.svg']
    },
    {
        id: 6,
        name: 'Карты',
        img: ['yandex_cyr_icon-icons.com_61627.svg', 'image.png', 'google_map_logo_icon_134443.svg']
    },
    {
        id: 7,
        name: 'Почту',
        img: ['gmail-svgrepo-com.svg', '32_@mail_sign_only_square_b.svg', 'yandex_cyr_icon-icons.com_61627.svg']
    },
]

export const ChoiceOfDelivery = [
    {
        id: 0,
        name: 'Бесплатная доставка курьером'
    },
    {
        id: 1,
        name: 'Оформление eSIM'
    },
    {
        id: 2,
        name: 'Прикрепление к пустой SIM'
    },
]

export const ChoiceOfPayment = [
    {
        id: 0,
        name: 'Оплата онлайн',
    },
    {
        id: 1,
        name: 'Оплата после получения SIM',
    }
]

export const Category = [
    {
        id: 1,
        name: 'Бронза',
        category: 'bronze',
        img: 'bronze.svg'
    },
    {
        id: 2,
        name: 'Серебро',
        category: 'silver',
        img: 'silver.svg'
    },
    {
        id: 3,
        name: 'Золото',
        category: 'gold',
        img: 'gold.svg'
    },
    {
        id: 4,
        name: 'Платина',
        category: 'platinum',
        img: 'platinum.svg'
    },
    {
        id: 5,
        name: 'Бриллиант',
        category: 'brilliant',
        img: 'brilliant.svg'
    },
]

export const AboutP = [
    {
        id: 1,
        big_p: '2012 года',
        little_p: 'Работаем с',
    },
    {
        id: 2,
        big_p: '500 000 +',
        little_p: 'Абонентов',
    },
    {
        id: 3,
        big_p: '1 000 000 +',
        little_p: 'Красивых номеров',
    },

]
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export function RewiewMap({ dataValue, dataEdit, setEdit }) {
  const [map, setMap] = useState(1);
  const [rewiewList, setRewiewList] = useState(dataValue);
  return (
    <>
      <div className='div_button'>
        {map < rewiewList?.length ? <button type='button' onClick={() => setMap(rewiewList?.length)}>Показать все отзывы</button> : <button type='button' onClick={() => setMap(1)}>Скрыть отзывы</button>}
      </div>

      <AddRewiew dataEdit={dataEdit} setEdit={setEdit}></AddRewiew>
      {rewiewList?.map((elem, index) => {
        let ret = '';
        if (index < map) {
          ret = (
            <Rewiew key={elem.id} elem={elem} obligatorily={false} dataEdit={dataEdit} setEdit={setEdit}></Rewiew>
          )
        }
        return ret;
      })}
    </>
  )
}

function Rewiew({ elem, obligatorily, dataEdit, setEdit, setAddRewiew }) {
  const [image, setImage] = useState(elem.img);
  const [imageFile, setImageFile] = useState()
  const [showEditImage, setShowEditImage] = useState(false);

  const [name, setName] = useState(elem.name);
  const [errorName, setErrorName] = useState(false);
  const [editValueName, setEditValueName] = useState(false);

  const [rewiew, setRewiew] = useState(elem.massage);
  const [errorRewiew, setErrorRewiew] = useState(false);
  const [editValueRewiew, setEditValueRewiew] = useState(false);

  const navigate = useNavigate();

  function canselButton() {
    setShowEditImage(false);
    setImage(elem.img);
    setName(elem.name);
    setEditValueName(false);
    setRewiew(elem.massage);
    setEditValueRewiew(false);
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (obligatorily) {
      setShowEditImage(true)
    }
    (name !== elem.name) ? setEditValueName(true) : setEditValueName(false);
    const editTextName = document.getElementById(elem.name);
    editValueName ? editTextName.className = 'edit' : editTextName.className = '';

    if (name === '' || name === 'Имя') {
      editTextName.className = 'error';
      setErrorName(true);
    } else {
      setErrorName(false)
    }

    (rewiew !== elem.massage) ? setEditValueRewiew(true) : setEditValueRewiew(false);
    const editText = document.getElementById(elem.massage);
    editValueRewiew ? editText.className = 'edit' : editText.className = '';

    if (rewiew === '' || rewiew === 'Отзыв') {
      editText.className = 'error';
      setErrorRewiew(true);
    } else {
      setErrorRewiew(false)
    }

  }, [name, setName, rewiew, setRewiew, elem, obligatorily])

  function editRewiew() {
    if (image && name !== 'Имя' && rewiew !== 'Отзыв') {
      const data = new FormData();
      data.append('id', elem.id)
      if (imageFile) {
        data.append('img', imageFile)
      }
      data.append('name', name)
      data.append('massage', rewiew)
      axios.put('https://api.bezlimit.club/comment', data, {
        headers: {
          'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
        }
      }).then((res) => {
        if (res.data.Response.message === "OK") {
          setEdit(!dataEdit)
        }
      })
      .catch(function (error) {
        navigate("/");
      })
    }
  }

  function createRewiew() {
    if (image && name !== 'Имя' && rewiew !== 'Отзыв') {
      const data = new FormData();
      data.append('img', imageFile)
      data.append('name', name)
      data.append('massage', rewiew)
      axios.post('https://api.bezlimit.club/comment', data, {
        headers: {
          'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
        }
      }).then((res) => {
        if (res.data.Response.message === "OK") {
          setEdit(!dataEdit)
        }
      })
      .catch(function (error) {
        navigate("/");
      })
    }
  }

  function deleteReview(id) {
    axios.delete('https://api.bezlimit.club/comment/' + id, {
      headers: {
        'Authorization': localStorage.getItem("token_type") + ' ' + localStorage.getItem("access_token"),
      }
    }).then((res) => {
      if (res.data.Response.message === "OK") {
        setEdit(!dataEdit)
      }
    })
    .catch(function (error) {
      navigate("/");
    })
  }

  return (
    <div className='review'>
      <h4>Отзыв #{elem.id}</h4>
      <form className='grid'>
        <p> Картинка #{elem.id}</p>

        <div className='center'>
          <img alt='' src={image} onClick={() => setShowEditImage(true)}></img>

          {showEditImage ? <div>
            <div className='div_button start'>
              <input id={elem.id} type='file' accept='image/*' className="custom-file-input" onChange={(e) => {
                setImageFile(e.target.files[0])
                setImage(URL.createObjectURL(e.target.files[0]))
              }
              }></input>
            </div>
          </div> : <div>
          </div>}
          <br />
        </div>
        <p>Имя #{elem.id}</p>
        <div>
          <textarea id={elem.name} rows={3} value={name} placeholder='Введите текст' onChange={(e) => setName(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}></textarea>
          {errorName ? <p className='error_p'>Обязательное поле для заполнения</p> : <></>}
        </div>
        <p>Отзыв #{elem.id}</p>
        <div>
          <textarea id={elem.massage} rows={3} value={rewiew} placeholder='Введите текст' onChange={(e) => setRewiew(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}></textarea>
          {errorRewiew ? <p className='error_p'>Обязательное поле для заполнения</p> : <></>}
        </div>
      </form>
      {obligatorily ? <div className='div_button'>
        <button type='button' onClick={() => setAddRewiew('')}>Отменить создание отзыва</button>
        <button type='button' className='button_sub' onClick={() => createRewiew()}>Создать отзыв</button>
      </div>
        : ((image !== elem.img || name !== elem.name || rewiew !== elem.massage) ?
          <div className='div_button'>
            <button type='button' onClick={() => deleteReview(elem.id)}>Удалить отзыв</button>
            <button type='button' onClick={() => canselButton()}>Отменить изменения</button>
            <button type='button' className='button_sub' onClick={() => editRewiew()}>Cохранить</button>
          </div> : <></>)
      }

    </div>
  )
}

function AddRewiew({ dataEdit, setEdit }) {
  const [addRewiew, setAddRewiew] = useState('')

  return (
    <>
      {addRewiew ? <>
        <Rewiew elem={addRewiew} obligatorily={true} setAddRewiew={setAddRewiew} dataEdit={dataEdit} setEdit={setEdit} />
      </> : <>
        <button type='button' onClick={() => setAddRewiew({ id: 'new', img: 'Ава', name: 'Имя', massage: 'Отзыв' })}>Добавить отзыв</button>
      </>}
    </>
  )
}


export default RewiewMap;
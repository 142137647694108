import { Routes, Route } from 'react-router-dom'
import Home from './Home';
import FormReg from './FormReg';
import Authorization from './admin/Authorization';
import AppAdmin from './admin/App_admin';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/number' element={<FormReg />} />
        <Route path="/admin" element={<Authorization />} />
        <Route path="/admin_home" element={<AppAdmin />} />
      </Routes>
    </div>

  )

}


export default App;
